import { useState } from "react";
import { Box, Typography, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyCode, ReportType, Service, ServiceAll } from "../../common";
import ServiceSelect from "../filters/serviceSelect";
import MonthSelect, { Months } from "../filters/monthSelect";
import YearSelect from "../filters/yearSelect";
import CurrencySelect from "../filters/currencySelect";
import TooltipButton from "../../tooltipButton/tooltipButton";
import LabelCheckbox from "../../labelCheckbox/labelCheckbox";
import { createFileReport } from "../../../redux/reducers/reportReducer";
import ReportTypeSelect from "../filters/reportTypeSelect";
import { isAws } from "../../../utils/billing";

const CreateNewReport = ({ ...containerProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [service, setService] = useState(Service.AWS);
  const [month, setMonth] = useState(
    Months[moment().format("MMMM").toUpperCase()],
  );
  const [year, setYear] = useState(moment().year());
  const [targetCurrency, setTargetCurrency] = useState(CurrencyCode.EUR);
  const [reportType, setReportType] = useState(ReportType.BASWARE);
  const { creatingReportDataMap } = useSelector((state) => state.report);
  const [useStoredData, setUseStoredData] = useState(false);

  const showWarning = () => {
    const now = moment();
    const currentMonth = now.month() + 1;
    const currentYear = now.year();
    return currentMonth === month && currentYear === year;
  };

  return (
    <Box sx={{ ...containerProps }}>
      <Typography variant="subtitle1">{t("createNewReport")}</Typography>
      {showWarning() === true && (
        <Box sx={{ py: 1 }}>
          <Alert severity="warning">{t("ongoingMonthWarning")}</Alert>
        </Box>
      )}
      <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
        <ReportTypeSelect
          reportType={reportType}
          reportTypes={ReportType}
          onReportTypeSelected={(value) => setReportType(value)}
        />
        {reportType === ReportType.BASWARE && (
          <ServiceSelect
            ml={2}
            service={service}
            serviceTypes={{
              AWS: Service.AWS,
              AZURE: Service.AZURE,
              GCP: Service.GCP,
            }}
            onServiceSelected={(value) => {
              setService(value);
              if (isAws(value) === false) {
                setTargetCurrency(CurrencyCode.EUR);
              }
            }}
          />
        )}
        <YearSelect ml={2} value={year} onSelect={(value) => setYear(value)} />
        <MonthSelect
          ml={2}
          value={month}
          onSelect={(value) => setMonth(value)}
        />
        {isAws(service) === true && reportType === ReportType.BASWARE && (
          <CurrencySelect
            ml={2}
            value={targetCurrency}
            onSelect={(newCurrency) => setTargetCurrency(newCurrency)}
          />
        )}
        <TooltipButton
          ml={2}
          buttonText={`${t("createFileReport")} - ${targetCurrency}`}
          onClick={() => {
            dispatch(
              createFileReport(
                reportType,
                reportType === ReportType.ACCOUNT ? ServiceAll : service,
                year,
                month,
                targetCurrency,
                useStoredData,
              ),
            ).then((report) => {
              if (report) {
                window.open(report.urls[0], "_blank");
              }
            });
          }}
          loading={creatingReportDataMap.get(month) === true}
        />
        <LabelCheckbox
          ml={2}
          label={t("useStoredData")}
          value={useStoredData}
          onChange={(value) => setUseStoredData(value)}
        />
      </Box>
    </Box>
  );
};

export default CreateNewReport;
