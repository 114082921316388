import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import BillingDataTable from "./billingDataTable";
import { useEffect, useState } from "react";
import StatusIcon, { StatusIconStatus } from "../statusIcon/statusIcon";
import BillingAggregatedTable from "./billingAggregatedTable";
import BillingReportTable from "./billingReportTable";
import CostSummary from "../billingSummary/costSummary";
import moment from "moment";
import { CurrencyCode } from "../../common";
import {
  calculateTotalCosts,
  filterRecordsByText,
} from "../../../utils/billing";

export const BillingMonthlyEntryMode = Object.freeze({
  AGGREGATED: "AGGREGATED",
  SERVICE: "SERVICE",
  REPORTS: "REPORTS",
});

const BillingMonthlyEntry = ({
  entryData,
  mode,
  monthNumber,
  components,
  recordCountLabel,
  complete,
  hideCost,
  hideOriginalCost,
  hideTimePeriodIcon,
  hideRecordCount,
  ...containerPops
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filteredEntryData, setFilteredEntryData] = useState(entryData);

  useEffect(() => {
    if (filterText.length > 0) {
      const filteredRecords = filterRecordsByText(
        entryData.records,
        filterText,
      );
      const costs = calculateTotalCosts(filteredRecords);
      setFilteredEntryData({
        ...entryData,
        records: filteredRecords,
        totalCost: costs.totalCost,
        preTaxTotalCost: costs.preTaxTotalCost,
        originalTotalCost: costs.originalTotalCost,
        preTaxOriginalTotalCost: costs.preTaxOriginalTotalCost,
      });
    } else {
      setFilteredEntryData(entryData);
    }
  }, [entryData, filterText]);

  const {
    totalCost,
    preTaxTotalCost,
    originalTotalCost,
    preTaxOriginalTotalCost,
    currency,
    originalCurrency,
    records,
  } = filteredEntryData;
  const createdAt = records.length > 0 ? records[0].createdAt : null;

  return (
    <Accordion
      expanded={open}
      onChange={(_e, value) => {
        setOpen(value);
      }}
      sx={{ ...containerPops }}
    >
      <AccordionSummary
        sx={{
          backgroundColor: open ? "primary.main" : "primary.contrastText",
          color: open ? "primary.contrastText" : "default",
          userSelect: "text",
        }}
        expandIcon={
          <ExpandMoreIcon
            sx={{
              color: open ? "primary.contrastText" : "default",
            }}
          />
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          {hideTimePeriodIcon !== true && (
            <StatusIcon
              mr={1}
              status={complete ? StatusIconStatus.Ok : StatusIconStatus.Warning}
              okTooltip={t("timePeriodComplete")}
              warningTooltip={t("timePeriodIncomplete")}
            />
          )}
          <Typography sx={{ minWidth: 100 }} variant="subtitle1">
            {`${t(`months.${monthNumber}`)}`}
          </Typography>
          <CostSummary
            totalCost={totalCost}
            preTaxTotalCost={preTaxTotalCost}
            originalTotalCost={originalTotalCost}
            preTaxOriginalTotalCost={preTaxOriginalTotalCost}
            currency={currency || CurrencyCode.EUR}
            originalCurrency={originalCurrency}
            complete={complete}
            recordCountLabel={recordCountLabel}
            recordCount={records.length}
            hideCost={hideCost}
            hideOriginalCost={hideOriginalCost}
            hideRecordCount={hideRecordCount}
          />
          <Box flexGrow={1} />
          {createdAt != null && (
            <Box sx={{ mr: 2 }}>
              <Typography variant="caption">
                {moment(createdAt).format("DD.MM.YYYY HH:mm:ss")}
              </Typography>
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          {components !== null && components}
          {mode === BillingMonthlyEntryMode.SERVICE && (
            <TextField
              sx={{ minWidth: 500, ml: components != null ? 2 : 0 }}
              label={t("filter")}
              placeholder={t("billingByServiceFilterPlaceholder")}
              size="small"
              variant="outlined"
              value={filterText || ""}
              onChange={(e) => setFilterText(e.target.value)}
            />
          )}
        </Box>
        {mode === BillingMonthlyEntryMode.SERVICE && (
          <BillingDataTable records={records} />
        )}
        {mode === BillingMonthlyEntryMode.AGGREGATED && (
          <BillingAggregatedTable records={records} />
        )}
        {mode === BillingMonthlyEntryMode.REPORTS && (
          <BillingReportTable records={records} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default BillingMonthlyEntry;
